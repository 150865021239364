import React from 'react';
import { useGoal } from 'gatsby-plugin-fathom';
import SingleColPageLayout from '../templates/single-col-page-layout';
import MetaTags from '../components/MetaTags';
import { fathomConfig } from '../../data/SiteConfig';

const Page = ({ path }) => {
  const handleGoal = useGoal(fathomConfig.goals.EMAIL_LIST_SUBSCRIBED);
  handleGoal(0);
  return (
    <SingleColPageLayout maxWidthClassName="max-w-4xl">
      <MetaTags title="Subscription complete" path={path} />
      <h1 className="text-center">Subscription complete! 😃</h1>
      <div className="bg-gray-200 p-8 mt-8 rounded-xl">
        <iframe
          title="Post subscription survey"
          className="airtable-embed"
          src="https://airtable.com/embed/shrxbimMUzY785yQH?backgroundColor=orange"
          frameBorder="0"
          onmousewheel=""
          width="100%"
          height="600"
        />
      </div>
    </SingleColPageLayout>
  );
};

export default Page;
